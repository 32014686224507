const addressInput = document.getElementById('address')

addressInput.addEventListener('focus', () => {
  const autocomplete = new google.maps.places.Autocomplete(
    addressInput,
    // eslint-disable-next-line no-undef
    { bounds: new google.maps.LatLngBounds(new google.maps.LatLng(43.602164, 1.444688)) },
    { types: ['geocode'] })
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()
    if (place.address_components) {
      const parsedAddress = parseAddressAutocomplete(place.address_components)
      document.getElementById('line1').value = parsedAddress.line1
      document.getElementById('zip_code').value = parsedAddress.zip_code
      document.getElementById('city').value = parsedAddress.city
      document.getElementById('country').value = parsedAddress.country
      document.getElementById('latitude').value = place.geometry.location.lat()
      document.getElementById('longitude').value = place.geometry.location.lng()
    }
  })
})

function parseAddressAutocomplete (address) {

  const route = this.checkIfExist(address, 'route')
  const neighborhood = this.checkIfExist(address, 'neighborhood')
  const streetNumber = this.checkIfExist(address, 'street_number')
  let line1 = ''
  if (streetNumber && route) line1 = streetNumber + ' ' + route
  else if (route) line1 = route
  else if (neighborhood) line1 = neighborhood
  else line1 = null
  const zip_code = this.checkIfExist(address, 'postal_code')
  const city = this.checkIfExist(address, 'locality')
  const country = this.checkIfExist(address, 'country')

  return { line1, city, country, zip_code }
}

function checkIfExist (address, key) {
  if (address.find(x => x.types.includes(key))) return address.find(x => x.types.includes(key)).long_name
  else return null
}